<template>
    <div>
        <Preloader v-if='loading'></Preloader>
        <div class='d-flex align-items-center justify-content-between mb-4'>
            <h4 class='page-title'>Рассылка</h4>
            <div class='widget__toolbar'>
                <div class='text-right'>
                    <h3 v-if='mailings && mailings.length === 0'>Нет рассылок, но вы можете создать новую</h3>
                    <button class='button_add btn__sync mr-3' @click='synchronizeContacts'>
                        <i class='fa fa-database' aria-hidden='true'></i> Синхронизация
                    </button>
                    <!--          <button class="button_add btn__add mr-3" @click="addData">-->
                    <!--            <i class="fa fa-plus" aria-hidden="true"></i> Создать сообщение-->
                    <!--          </button>-->
                    <button class='button_add btn__add' @click='addCampagin'>
                        <i class='fa fa-plus' aria-hidden='true'></i> Создать кампанию
                    </button>

                </div>
            </div>
        </div>

        <div class='widget'>

            <div class='table_wrapper'>
                <table class='table table-hover' v-if='mailings'>
                    <thead class='table_head'>
                    <tr>
                        <th>Тема</th>
                        <th class='text-center'>Отправитель</th>
                        <th class='text-center'>Дата отправки</th>
                        <th class='text-center'>Email отправителя</th>
                        <th class='text-center'>Статистика</th>
                        <th class='text-center actions'>Действия</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for='item in mailings' :key='item.id'>
                        <td>{{ item.subject }}</td>
                        <td class='text-center'>{{ item.sender_name }}</td>
                        <td class='text-center'>{{ item.start_time }}</td>
                        <td class='text-center'>{{ item.sender_email }}</td>
                        <td class='text-center'><a :href='item.stats_url' target='_blank'>перейти</a></td>
                        <td class='text-center'>
                            <router-link class='leads_table_action_link btn mr-2 btn-primary'
                                         :to="{name: 'EmailCampaignView', params: {id: item.message_id}, query: {
                                             campaignId: item.id
                                         }}">
                                <span class='glyphicon glyphicon-edit' />
                            </router-link>
                            <!--              <a class="btn mr-2 btn-danger" @click.prevent="deleteRow(item.id, item.subject)">-->
                            <!--                <span class="fa fa-trash-o delete"></span>-->
                            <!--              </a>-->
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import Preloader from '../../components/Preloader';

export default {
    name: 'EmailSend',
    components: { Preloader },
    data() {
        return {
            mailings: null,
            loading: false,
            editMessageMode: false,
        };
    },
    methods: {
        async fetchData() {
            try {
                const { data } = await this.$axios.get('/api/mailing/email/campaign');
                if (data && data.result) {
                    this.mailings = data.result;
                    this.toggleLoader();
                }
                console.log(data);
            } catch (e) {
                console.log(e);
            }
        },
        toggleLoader() {
            this.loading = !this.loading;
        },
        async addCampagin() {
            this.$router.push({ name: 'EmailCampaignCreate' });
        },
        async synchronizeContacts() {
            this.toggleLoader();
            try {
                const { data } = await this.$axios.get('api/mailing/email/contact/sync');
                if (data && data.result) {
                    this.$toasted.success(`Список контактов CRM обновлен`, {
                        position: 'bottom-right',
                    });
                }
                console.log(data);
            } catch (e) {
                console.log(e);
            }
            this.toggleLoader();
        },
        addData() {
            this.$router.push({ name: 'EmailCreate' });
        },
    },
    async created() {
        this.toggleLoader();
        await this.fetchData();
    },
};
</script>

<style scoped lang='scss'>
.widget__toolbar {
    padding: 5px 0px;
}

.btn__add {
    height: 30px;
    //float: right;
    color: #333;
    font-weight: bold;
    text-transform: uppercase;
    //border: 1px solid #333;
    background: none;
}

.btn__sync {
    @extend .btn__add;
    color: #999;
    border: none;
}
</style>